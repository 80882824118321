<template>
  <el-form :model="form" size="small" inline class="search-form">
    <el-form-item label="版本号：" prop="version">
      <el-input v-model="form.version" placeholder="请输入版本号" />
    </el-form-item>
    <el-form-item label="创建人：" prop="createUserName">
      <el-input v-model="form.createUserName" placeholder="请输入创建人" />
    </el-form-item>
    <el-form-item label="创建时间：" class="form-item">
      <el-date-picker
        v-model="time"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        class="form-item-time"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item class="form-item" label-width="30px">
      <el-button size="small" type="primary" @click="search">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'AgreeSearch',
  data() {
    return {
      time: '',
      form: {
        version: '',
        createUserName: '',
        createTimeStart: '',
        createTimeEnd: ''
      }
    };
  },
  watch: {
    time(newVal, oldVal) {
      if (newVal) {
        this.form.createTimeStart = dayjs(newVal[0]).format('YYYY-MM-DD HH:mm:ss');
        this.form.createTimeEnd = dayjs(newVal[1]).format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.form.createTimeStart = '';
        this.form.createTimeEnd = '';
      }
    }
  },
  methods: {
    search() {
      this.$emit('search', this.form);
    },
    reset() {
      this.form.version = '';
      this.time = '';
      this.form.version = '';
      this.form.createTimeEnd = '';
      this.form.createTimeStart = '';
      this.form.createUserName = '';
      this.$emit('reset', this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-form {
  padding: 0 20px;
}
</style>
