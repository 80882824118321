import request from '@/utils/request';

export function getAction(url, data) {
  return request({
    url,
    method: 'GET',
    data
  });
}

export function postAction(url, data, options = {}) {
  return request({
    url,
    method: 'POST',
    data,
    ...options
  });
}

export function deleteAction(url, data, options) {
  return request({
    url,
    method: 'POST',
    data,
    ...options
  });
}
export function exportAction(url, data, options) {
  return request({
    url,
    method: 'POST',
    data,
    responseType: 'blob',
    headers: { 'Content-Type': 'application/json; application/octet-stream' }
  });
}
export default {};
