// 协议管理

import request from '@/utils/request';

// 新增协议
export function addAgreement (data) {
  return request({
    url: "misc-web-api/admin/agreement/save",
    method: 'POST',
    data,
  })
}
// 删除协议
export function deleteAgreement (data) {
  return request({
    url: "misc-web-api/admin/agreement/delete",
    method: 'POST',
    data,
  })
}

export function agreementDetail (data) {
  return request({
    url: "misc-web-api/admin/agreement/detail",
    method: 'POST',
    data,
  })
}

export default {}
