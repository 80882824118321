<template>
  <section>
    <el-tabs v-model="type">
      <el-tab-pane label="用户协议" name="1"></el-tab-pane>
    </el-tabs>
    <agreement-table ref="AgreementTable" :type="type" />
  </section>
</template>

<script>
import AgreementTable from './components/AgreemenTable.vue';

export default {
  name: 'agreement',
  components: {
    AgreementTable
  },
  data() {
    return {
      type: '1'
    };
  }
};
</script>

<style lang="less" scoped></style>
