<template>
  <div class="agreement-detail" v-loading="loading">
    <div class="header">协议详情</div>
    <el-form class="form" size="small" label-width="60px">
      <el-form-item label="版本号：">
        <el-input :value="detailData.version" readonly style="color: #000" />
      </el-form-item>
      <el-form-item label="内容：">
        <div class="textarea" v-html="detailData.content"></div>
      </el-form-item>
      <el-form-item label="图片：">
        <span v-if="detailData.picUrl === ''"></span>
        <el-image
          v-else
          style="width: 100px; height: 100px"
          :src="detailData.picUrl"
          :preview-src-list="[detailData.picUrl]"
        >
        </el-image>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { agreementDetail } from '@/api/agreement';

export default {
  name: 'Detail',
  data() {
    return {
      loading: false,
      detailData: {}
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const { id } = this.$route.query;
      if (!id) {
        this.$message.error('参数不正确');
        return;
      }
      try {
        this.loading = true;
        const params = {
          data: {
            id
          }
        };
        const res = await agreementDetail(params);
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        res.picUrl = res.picUrl ? JSON.parse(res.picUrl)[0] : '';
        this.detailData = res;
      } catch (e) {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="less">
.agreement-detail {
  .header {
    font-size: 16px;
    padding: 15px 20px;
    font-weight: 500;
    color: #000;
    border-bottom: 1px solid #e0e0e0;
  }
  .form {
    padding: 20px;
    .textarea {
      background: #f5f7fa;
      min-height: 200px;
      padding: 0 15px;
      color: #000;
      border-radius: 4px;
      font-size: 12px;
      border: 1px solid #dfe4ed;
    }
  }
}
</style>
