import request from '@/utils/request';

export function agreementSave(data) {
  return request({
    url: "/misc-web-api/admin/agreement/save",
    method: 'POST',
    data,
  })
}
export function queryByType(data) {
  return request({
    url: "/misc-web-api/admin/agreement/queryByType",
    method: 'POST',
    data,
  })
}

export default {}
