<template>
  <div class="wrap" v-loading="loading">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="售后说明设置" name="2"
        ><el-input
          type="textarea"
          style="width: 700px"
          rows="20"
          placeholder="请输入售后说明内容"
          v-model="content2"
        >
        </el-input>
      </el-tab-pane>
      <el-tab-pane label="预约改期须知设置" name="3"
        ><el-input
          type="textarea"
          rows="20"
          style="width: 700px"
          placeholder="请输入预约改期须知内容"
          v-model="content3"
        >
        </el-input>
      </el-tab-pane>
      <el-tab-pane label="客服取消预约须知设置" name="4"
        ><el-input
          type="textarea"
          rows="20"
          style="width: 700px"
          placeholder="请输入客服取消预约须知内容"
          v-model="content4"
        >
        </el-input>
      </el-tab-pane>
      <div class="btn">
        <!-- <el-button>默认按钮</el-button> -->
        <el-button type="primary" @click="save">保存设置</el-button>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import { agreementSave, queryByType } from '@/api/businessDesc';

export default {
  data() {
    return {
      activeName: '2',
      content2: '',
      content3: '',
      content4: '',
      loading: false
    };
  },
  mounted() {
    this.queryByType(2);
  },
  watch: {
    activeName: function (val) {
      this.queryByType(val);
    }
  },
  methods: {
    queryByType(val) {
      this.loading = true;
      queryByType({
        data: {
          type: val
        }
      })
        .then((res) => {
          this.loading = false;
          if (this.activeName === '2') {
            this.content2 = res.content;
          }
          if (this.activeName === '3') {
            this.content3 = res.content;
          }
          if (this.activeName === '4') {
            this.content4 = res.content;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    save() {
      //   console.log(this['content' + this.activeName]);
      let content = '';
      if (this.activeName === '2') {
        content = this.content2;
      }
      if (this.activeName === '3') {
        content = this.content3;
      }
      if (this.activeName === '4') {
        content = this.content4;
      }
      agreementSave({
        data: {
          type: this.activeName,
          content
        }
      }).then((res) => {
        this.queryByType(this.activeName);
        this.$message({
          message: '保存成功',
          type: 'success'
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 16px;
  /deep/ .el-tabs__item {
    color: #000;
  }
  /deep/.is-active {
    background-color: #f5f7fa !important;
    border-color: #f5f7fa !important;
    border-bottom-color: #dfe4ed !important;
  }
  .btn {
    padding-top: 20px;
  }
}
</style>
