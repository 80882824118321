import { isEmpty } from 'lodash';
import { getAction, postAction } from '@/api/manage';

const TableModalMinix = {
  data() {
    return {
      defaultForm: {},
      form: {},
      isEdit: false,
      isCheckRepeat: true,
      confirmLoading: false
    };
  },
  methods: {
    handleCancel() {
      console.log('111');
      if (isEmpty(this.defaultForm)) {
        console.error('defaultForm不能为空对象');
      }
      this.form = {
        ...this.defaultForm
      };
      this.visible = false;
      this.$refs.modalFrom.resetFields();
      this.$refs.modalFrom.clearValidate();
    },
    // 数据新增
    async handleModalAdd(data, successMessage) {
      try {
        const res = await postAction(this.url.add, data,{ allResult: true });
        if (res.success) {
          // this.$message.error(res.description)
          this.isLeader = false
          this.handleCancel();
          this.$emit('ok', res.data);
          if (successMessage) {
            this.$message.success(successMessage);
          } else {
            this.$message.success('新增成功');
          }
          return;
        }
        if(res.description === '该员工为直属上级'){
          this.isLeader = true
          return
        }
      } catch (err) {
        this.isLeader = false
        console.log(err);
      }
    },
    // 数据更新
    async handleModalUpdate(data, successMessage) {
      this.confirmLoading = true;
      try {
        const res = await postAction(this.url.update, data, { allResult: true });
        this.confirmLoading = false;
        if (res.success) {
          console.log(res);
          // this.$message.error(res.description)
          this.isLeader = false
          this.handleCancel();
          this.$emit('ok', res.data);
          if (successMessage) {
            this.$message.success(successMessage);
          } else {
            this.$message.success('编辑成功');
          }
          return;
        }
        if(res.description === '该员工为直属上级'){
          this.isLeader = true
          return
        }
      } catch (error) {
        this.isLeader = false
        this.confirmLoading = false;
        console.log(error);
      }
    }
  }
};

export default TableModalMinix;
